import React from "react";

import { useAuth0 } from '@auth0/auth0-react';
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import LoginButton from "../components/LoginButton";
import LogoutButton from "../components/LogoutButton";
import Layout from "../components/layout";
import SEO from "../components/seo";
import IndexCard from "../components/IndexCard";


const IndexPage: React.FunctionComponent = () => {
  const { user } = useAuth0();
  const hasFWRole = user && (user['https://future-workforce.demo.alephinsights.com/roles']?.includes('access-content'));
  let sorry;
  if (!hasFWRole) {
    if (user) {
      sorry = <>Your account has not been approved for access to this site. Please request permission from Dstl.<div className='center'><LogoutButton/></div></>;
    }
    else {
      sorry = <>This site is now closed. Please contact the Future Workforce Team at the Defence Science and Technology Laboratory for details of how to continue to access the tool.</>;
    }
  }
  return (
    <Layout pageHeader="" moreInfo='index'>
      <SEO
        keywords={[`future`, `workforce`, `modelling`, `tool`]}
        title="Home"
      />
      {hasFWRole ? (
        <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4">
          <div className="mb-8">
            <p>This software tool has been developed for the Defence Science and Technology Laboratory (Dstl). It provides an interactive summary of the outputs of a research project which provides insights into the UK workforce over the next 20 years. It focuses on the ability of Defence to engage with broader society in order to recruit and retain the people it will need in the future. For more information or support when using the tool use the ‘more info’ button in the top right hand corner of each page.</p>
          </div>
          <section>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <IndexCard imageSource="/image/pop-model.svg" link="/modelling" title="Workforce Population Modelling" description="This element presents interactive visualisations which enable the exploration of data concerning the UK population over the next 20 years." />
              <IndexCard imageSource="/image/survey-data.svg" link="/deliberative-survey" title="Attitudes Towards Defence" description="This element contains the results of a deliberative survey conducted with young adults, their parents or carers, and career gatekeepers." />
              <IndexCard imageSource="/image/scenarios.svg" link="/scenarios" title="Future Workforce Scenarios" description="This element contains information about a series of six scenarios exploring themes that will have a bearing on the future UK workforce." />
              <IndexCard imageSource="/image/recommendations.svg" link="/recommendations" title="Research Recommendations" description="This element contains the main recommendations of the research project." />
              <IndexCard imageSource="/image/spy-glass.svg" link="/evidence-catalogue" title="Evidence Catalogue" description="This element contains the original research reports produced by this project which underpin the information presented within the tool." />
            </div>
          </section>
        </div>
      ): <div className="flex items-end justify-center pt-4 px-4 text-center sm:block sm:p-0">
        <div className= "inline-block bg-white rounded-lg shadow-xl my-12 align-middle p-6 l:w-4/12 sm:w-6/12">
          <div>
            <div className="mx-auto flex items-center justify-center">
              <ExclamationCircleIcon className="h-12 w-12 text-dstl-crimson" aria-hidden="true" />
            </div>
            <div className="max-h-[50vh] overflow-auto mt-3 text-center sm:mt-5">
              {sorry}
            </div>
          </div>
        </div>
      </div>
      }

    </Layout>
  )
}

export default IndexPage;
