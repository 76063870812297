import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function LogoutButton() {
  const {
    isAuthenticated,
    logout,
  } = useAuth0();

  return isAuthenticated? (
    <>
      <button className="relative  bg-white border border-gray-300 hover:bg-dstl-light-crimson cursor-pointer rounded-lg my-1 py-1 px-2 font-bold" type='button' onClick={() => {
        logout({ returnTo: window.location.origin });
      }}> Log out</button>
    </>
  ):'';
}

export default LogoutButton;
